import queryString from 'query-string';

import * as AppConstants from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined
};

export function getNetworkDetailsData(
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_DETAILS_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_DETAILS_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          error: {
            ...state.error,
            [widgetId]: true
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.loading,
            [widgetId]: false
          }
        };
      })();

    case AppConstants.GET_NETWORK_DETAILS_DATA_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.error]: false
        }
      };

    default:
      return state;
  }
}

export function deleteNetworkNode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.DELETE_NETWORK_NODE:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_NETWORK_NODE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_NETWORK_NODE_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.DELETE_NETWORK_NODE_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function updateNetworkNode(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.UPDATE_NETWORK_NODE:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_NETWORK_NODE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_NETWORK_NODE_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.UPDATE_NETWORK_NODE_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export const getNetworkParameters = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_PARAMETERS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_PARAMETERS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.param.widgetId]: action.response },
        loading: { ...state.loading, [action.param.widgetId]: false },
        success: { ...state.success, [action.param.widgetId]: true }
      };

    case AppConstants.GET_NETWORK_PARAMETERS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          error: { ...state.error, [widgetId]: true },
          loading: { ...state.loading, [widgetId]: false },
          success: { ...state.loading, [widgetId]: false }
        };
      })();

    default:
      return state;
  }
};

export function getNodeParameters(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NODE_PARAMETERS:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.GET_NODE_PARAMETERS_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.GET_NODE_PARAMETERS_FAILURE:
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.GET_NODE_PARAMETERS_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function getNodeConfig(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NODE_CONFIG:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.GET_NODE_CONFIG_SUCCESS:
      return {
        ...state,
        data: action.response.config.groups,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.GET_NODE_CONFIG_FAILURE:
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.GET_NODE_CONFIG_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function createNodeParameters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.CREATE_NODE_PARAMETERS:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_NODE_PARAMETERS_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NODE_PARAMETERS_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.CREATE_NODE_PARAMETERS_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function createNodeConfig(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.CREATE_NODE_CONFIG:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_NODE_CONFIG_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NODE_CONFIG_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.CREATE_NODE_CONFIG_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function setNetworkMainNode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.SET_NETWORK_MAIN_NODE:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.SET_NETWORK_MAIN_NODE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.SET_NETWORK_MAIN_NODE_FAILURE:
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        success: false
      };

    case AppConstants.SET_NETWORK_MAIN_NODE_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
}

export function getNodeSubtree(
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NODE_SUBTREE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NODE_SUBTREE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.param.widgetId]: action.response },
        loading: { ...state.loading, [action.param.widgetId]: false },
        success: { ...state.success, [action.param.widgetId]: true }
      };

    case AppConstants.GET_NODE_SUBTREE_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          error: { ...state.error, [widgetId]: true },
          loading: { ...state.loading, [widgetId]: false },
          success: { ...state.loading, [widgetId]: false }
        };
      })();

    case AppConstants.GET_NODE_SUBTREE_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.error]: false
        }
      };

    default:
      return state;
  }
}

export const getNetworkNodeLocationConfig = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_NODE_LOCATION_CONFIG:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widget_id]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widget_id]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widget_id]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widget_id]: false
        }
      };

    case AppConstants.GET_NETWORK_NODE_LOCATION_CONFIG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widget_id]: {
            ...(action.response ?? {}),
            ...action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widget_id]: false
        },
        success: {
          ...state.success,
          [action.param.widget_id]: true
        }
      };

    case AppConstants.GET_NETWORK_NODE_LOCATION_CONFIG_FAILURE:
      return (() => {
        const widget_id = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widget_id : url.widget_id;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widget_id]: false
          },
          success: {
            ...state.success,
            [widget_id]: false
          },
          error: {
            ...state.error,
            [widget_id]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_NODE_LOCATION_CONFIG_RESET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widget_id]: false
        },
        success: {
          ...state.success,
          [action.payload.widget_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widget_id]: false
        }
      };

    default:
      return state;
  }
};

export const updateNetworkNodeLocationConfig = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.UPDATE_NETWORK_NODE_LOCATION_CONFIG:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widget_id]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widget_id]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widget_id]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widget_id]: false
        }
      };

    case AppConstants.UPDATE_NETWORK_NODE_LOCATION_CONFIG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widget_id]: {
            ...(action.response.result ?? {}),
            ...action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widget_id]: false
        },
        success: {
          ...state.success,
          [action.param.widget_id]: true
        }
      };

    case AppConstants.UPDATE_NETWORK_NODE_LOCATION_CONFIG_FAILURE:
      return (() => {
        const widget_id = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widget_id : url.widget_id;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widget_id]: false
          },
          success: {
            ...state.success,
            [widget_id]: false
          },
          error: {
            ...state.error,
            [widget_id]: true
          }
        };
      })();

    case AppConstants.UPDATE_NETWORK_NODE_LOCATION_CONFIG_RESET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widget_id]: false
        },
        success: {
          ...state.success,
          [action.payload.widget_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widget_id]: false
        }
      };

    default:
      return state;
  }
};

export const getNetworkAssetCbmAsset = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_ASSET_CBM_ASSET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widget_id]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widget_id]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widget_id]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widget_id]: false
        }
      };

    case AppConstants.GET_NETWORK_ASSET_CBM_ASSET_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widget_id]: {
            ...action.param,
            ...(action.response ?? {})
          }
        },
        loading: {
          ...state.loading,
          [action.param.widget_id]: false
        },
        success: {
          ...state.success,
          [action.param.widget_id]: true
        }
      };

    case AppConstants.GET_NETWORK_ASSET_CBM_ASSET_FAILURE:
      return (() => {
        const widget_id = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widget_id : url.widget_id;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widget_id]: false
          },
          success: {
            ...state.success,
            [widget_id]: false
          },
          error: {
            ...state.error,
            [widget_id]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_ASSET_CBM_ASSET_RESET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widget_id]: false
        },
        success: {
          ...state.success,
          [action.payload.widget_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widget_id]: false
        }
      };

    default:
      return state;
  }
};

export const getNetworkAssetMeta = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_ASSET_META:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widget_id]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widget_id]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widget_id]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widget_id]: false
        }
      };

    case AppConstants.GET_NETWORK_ASSET_META_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widget_id]: {
            param: action.param,
            response: action.response
          }
        },
        loading: {
          ...state.loading,
          [action.param.widget_id]: false
        },
        success: {
          ...state.success,
          [action.param.widget_id]: true
        }
      };

    case AppConstants.GET_NETWORK_ASSET_META_FAILURE:
      return (() => {
        const widget_id = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widget_id : url.widget_id;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widget_id]: false
          },
          success: {
            ...state.success,
            [widget_id]: false
          },
          error: {
            ...state.error,
            [widget_id]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_ASSET_META_RESET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widget_id]: false
        },
        success: {
          ...state.success,
          [action.payload.widget_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widget_id]: false
        }
      };

    default:
      return state;
  }
};

export const widgetsReducer = {
  getNetworkDetailsData,
  deleteNetworkNode,
  updateNetworkNode,
  getNetworkParameters,
  getNodeParameters,
  createNodeParameters,
  createNodeConfig,
  setNetworkMainNode,
  getNodeSubtree,
  getNetworkNodeLocationConfig,
  updateNetworkNodeLocationConfig,
  getNetworkAssetCbmAsset
};
