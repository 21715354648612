import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import { httpMethodGetJSON } from '../../../../https';
import * as AppConstants from './constant';

export const getNetworkInsights = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network-insights/`);
    })
  );
};
