import queryString from 'query-string';

import * as AppConstants from './constant';

import {
  getPlantHoliday,
  createPlantHoliday,
  updatePlantHoliday,
  deletePlantHoliday
} from './_components/HolidayList/reducer';

import { createUpdateWorkingNonWorking, getWorkingNonWorkingHourDetails } from './_components/WorkingNonWorkingHoursConfiguration/reducer';

import { createUpdateProductionNonProductionHour, getProductionNonProductionHourDetails } from './_components/ProductionNonProductionHoursConfiguration/reducer';

import { createUpdateShift, getShiftDetails } from './_components/ShiftConfiguration/reducer';

import { createDynamicVariableTemplate } from './_components/DynamicVariableTemplateCreation/reducer';

import { createDynamicVariableMapping } from './_components/DynamicVariableConfiguration/reducer';

import {
  createNetworkCalculatedParamTemplate,
  updateNetworkCalculatedParamTemplate
} from './_components/CalculatedParameterCreation/reducer';

const INITIAL_STATE = {
    data: [],
    checkedData: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
    isScreenDesignOpen: false,
    isAllTemplateChecked: false
}

const PARAM_INITIAL_STATE = {
    data: [],
    checkedData: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
    isCalculatedParameterOpen: false,
    isAllCalculatedParameterChecked: false
}

export function getCalculatedParameterList(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export function calculatedParameterModal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.IS_OPEN_CALCULATED_PARAMETER_MODAL:
            return {
                ...state,
                isCalculatedParameterOpen: action.payload
            }
        default:
            return state;
    }
}

export function getAllClientList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_CLIENT_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_CLIENT_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getAllPlantList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_PLANT_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_PLANT_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_PLANT_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function deleteCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_CALCULATED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_SUCCESS:

            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function createCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_CALCULATED_PARAMETERS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CREATE_CALCULATED_PARAMETERS_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.CREATE_CALCULATED_PARAMETERS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message 
            }
        case AppConstants.CREATE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function updateCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_CALCULATED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function verifyTargetSensorParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getTemplateList(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_ALL_TEMPLATE_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export function getTemplateDetails(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_TEMPLATE_DETAILS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        default:
            return state;
    }
}

export function getAssetNameDetails(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ASSET_NAME_DETAILS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ASSET_NAME_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ASSET_NAME_DETAILS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        default:
            return state;
    }
}

export function updateTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_TEMPLATE_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function deleteTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_TEMPLATE_SUCCESS:

            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_TEMPLATE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getAllAssetList(state = {
	data: [],
	loading: false,
	error: false,
	success: undefined,
}, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_ASSET_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_ASSET_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_ASSET_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function createTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_TEMPLATES:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CREATE_TEMPLATES_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.CREATE_TEMPLATES_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message
            }
        case AppConstants.CREATE_TEMPLATE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

// export function getMetaDataForm(state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case AppConstants.GET_ALL_METADATA:
//             return {
//                 ...state,
//                 loading: true,
//                 data: [],
//                 error: false,
//                 success: undefined,
//                 message: null,
//             }
//         case AppConstants.GET_ALL_METADATA_SUCCESS:
//             return {
//                 ...state,
//                 data: action.response.result,
//                 total_pages: action.response.total_pages,
//                 total_record: action.response.total_record,
//                 error: false,
//                 loading: false,
//                 success: true,
//                 message: null,
//             }
//         case AppConstants.GET_ALL_METADATA_FAILURE:
//             return {
//                 ...state,
//                 error: true,
//                 data: [],
//                 success: false,
//                 message: action.payload.response.message,
//                 loading: false,
//             }
//         default:
//             return state;
//     }
// }

// export function getListOfAvailableDashboard(state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case AppConstants.GET_ALL_DASHBOARD_LIST:
//             return {
//                 ...state,
//                 loading: true,
//                 data: [],
//                 error: false,
//                 success: undefined,
//                 message: null,
//             }
//         case AppConstants.GET_ALL_DASHBOARD_LIST_SUCCESS:
//             return {
//                 ...state,
//                 data: action.response.result,
//                 total_pages: action.response.total_pages,
//                 total_record: action.response.total_record,
//                 error: false,
//                 loading: false,
//                 success: true,
//                 message: null,
//             }
//         case AppConstants.GET_ALL_DASHBOARD_LIST_SUCCESS:
//             return {
//                 ...state,
//                 error: true,
//                 data: [],
//                 success: false,
//                 message: null,
//                 loading: false,
//             }
//         default:
//             return state
//     }
// }

export function screenDesignModal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.IS_OPEN_SCREEN_DESIGN_MODAL:
            return {
                ...state,
                isScreenDesignOpen: action.payload
            }
        default:
            return state;
    }
}

export function addTemplateWidget(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.ADD_TEMPLATE_WIDGET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.ADD_TEMPLATE_WIDGET_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.ADD_TEMPLATE_WIDGET_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }

        case AppConstants.ADD_TEMPLATE_WIDGET_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getTemplateWidgets(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_TEMPLATE_WIDGETS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_TEMPLATE_WIDGETS_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_TEMPLATE_WIDGETS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getWidgetDetailsOfTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_WIDGET_DETAILS_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_WIDGET_DETAILS_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_WIDGET_DETAILS_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getGroupWidgetDetailsOfTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_GROUP_WIDGET_DETAILS_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_GROUP_WIDGET_DETAILS_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_GROUP_WIDGET_DETAILS_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function updateWidgetTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_WIDGET_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_WIDGET_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_WIDGET_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_WIDGET_TEMPLATE_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function deleteWidgetOfTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_WIDGET_OF_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_WIDGET_OF_TEMPLATE_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_WIDGET_OF_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_WIDGET_OF_TEMPLATE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getDefaultTemplateAsset(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getServiceTemplatePlant(state = {
	data: [],
	loading: false,
	success: undefined,
	error: false
}, action) {
    switch (action.type) {
        case AppConstants.GET_SERVICE_TEMPLATE_PLANT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_SERVICE_TEMPLATE_PLANT_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_SERVICE_TEMPLATE_PLANT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getClientTemplate(state = {
	data: [],
	loading: false,
	success: undefined,
	error: false
}, action) {
    switch (action.type) {
        case AppConstants.GET_CLIENT_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_CLIENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_CLIENT_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function createServiceTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_SERVICE_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CREATE_SERVICE_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.CREATE_SERVICE_TEMPLATE_FAILURE:
            return {
              ...state,
              error: true,
              data: [],
              success: false,
              loading: false,
              message:
                action.payload?.response?.message ||
                action.payload?.response?.error
            };

        case AppConstants.CREATE_SERVICE_TEMPLATE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getServiceTemplate(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_SERVICE_TEMPLATE:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_SERVICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_SERVICE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export function updateServiceTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_SERVICE_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_SERVICE_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_SERVICE_TEMPLATE_FAILURE:
            return {
              ...state,
              error: true,
              data: [],
              success: false,
              message:
                action.payload?.response?.message ||
                action.payload?.response?.error
            };
        case AppConstants.UPDATE_SERVICE_TEMPLATE_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function deleteServiceTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_SERVICE_TEMPLATE:
            return {
                ...state,
                loading: true,
                data: [],

                error: false,
                success: undefined
            }
        case AppConstants.DELETE_SERVICE_TEMPLATE_SUCCESS:

            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_SERVICE_TEMPLATE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_SERVICE_TEMPLATE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getWidgetsDetails(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_WIDGETS_DETAILS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_WIDGETS_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_WIDGETS_DETAILS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        default:
            return state;
    }
}

export function createDashboardTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.CREATE_DASHBOARD_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_DASHBOARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_DASHBOARD_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message: action.payload?.response?.error
      };

    case AppConstants.CREATE_DASHBOARD_TEMPLATE_RESET:
      return {
        ...state,
        loading: false,
        data: [],
        error: false,
        success: undefined
      };
    default:
      return state;
  }
}

export function getTemplateDashboard(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_DASHBOARD_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.GET_DASHBOARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.GET_DASHBOARD_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false
      };

    default:
      return state;
  }
}

export function addDashboardWidget(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.ADD_DASHBOARD_WIDGET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.ADD_DASHBOARD_WIDGET_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.ADD_DASHBOARD_WIDGET_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message
            }

        case AppConstants.ADD_DASHBOARD_WIDGET_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getDashboardWidgets(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_DASHBOARD_WIDGETS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_DASHBOARD_WIDGETS_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_DASHBOARD_WIDGETS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        default:
            return state;
    }
}

export function deleteDashboardWidget(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_DASHBOARD_WIDGET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_DASHBOARD_WIDGET_SUCCESS:
           
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_DASHBOARD_WIDGET_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_DASHBOARD_WIDGET_RESET:
            return {
                ...state,
                loading: false,
                error: false,
                success: false
            }
        default:
            return state;
    }
}

export function updateDashboardWidget(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.EDIT_DASHBOARD_WIDGET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.EDIT_DASHBOARD_WIDGET_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.EDIT_DASHBOARD_WIDGET_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.EDIT_DASHBOARD_WIDGET_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function editDashboardOrder(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.EDIT_DASHBOARD_ORDER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.EDIT_DASHBOARD_ORDER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.EDIT_DASHBOARD_ORDER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.EDIT_DASHBOARD_ORDER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function editWidgetService(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.EDIT_WIDGET_SERVICE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.EDIT_WIDGET_SERVICE_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.EDIT_WIDGET_SERVICE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.EDIT_WIDGET_SERVICE_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function makeServiceDefault(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.SERVICE_DEFAULT:
            let initalData = state.data;
            initalData[action.payload.service_id] = {}
            return {
                ...state,
                loading: true,
                data: initalData,
                error: false,
                success: undefined
            }

        case AppConstants.SERVICE_DEFAULT_SUCCESS:
            let oldData = state.data;
            oldData[action.param.service_id] = action.response
            return {
                ...state,
                data: oldData,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.SERVICE_DEFAULT_FAILURE:
            return {
                ...state,
                error: true,
                data: {},
                success: false,
                loading: false,
            }

        case AppConstants.SERVICE_DEFAULT_RESET:
          return {
            ...state,
            loading: false,
            success: undefined,
            error: false
          };

        default:
            return state;
    }
}

export const makeServiceDefaultExtended = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.SERVICE_DEFAULT_EXTENDED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.service_id]: null
        },
        loading: {
          ...state.loading,
          [action.payload.service_id]: true
        },
        success: {
          ...state.success,
          [action.payload.service_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.service_id]: false
        }
      };

    case AppConstants.SERVICE_DEFAULT_EXTENDED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.service_id]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.service_id]: false
        },
        success: {
          ...state.success,
          [action.param.service_id]: true
        },
        error: {
          ...state.error,
          [action.param.service_id]: false
        }
      };

    case AppConstants.SERVICE_DEFAULT_EXTENDED_FAILURE:
      return (() => {
        const { service_id } = queryString.parse(
          action.payload.request.url.replace(/.*\?/, '')
        );

        return {
          ...state,
          loading: {
            ...state.loading,
            [service_id]: false
          },
          success: {
            ...state.success,
            [service_id]: false
          },
          error: {
            ...state.error,
            [service_id]: true
          }
        };
      })();

    case AppConstants.SERVICE_DEFAULT_EXTENDED_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.service_id]: null
        },
        loading: {
          ...state.loading,
          [action.payload.service_id]: false
        },
        success: {
          ...state.success,
          [action.payload.service_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.service_id]: false
        }
      };

    default:
      return state;
  }
};

export function createAssetCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_ASSET_CALCULATED_PARAMETERS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CREATE_ASSET_CALCULATED_PARAMETERS_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.CREATE_ASSET_CALCULATED_PARAMETERS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message 
            }
        case AppConstants.CREATE_ASSET_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function updateAssetCalculatedParameter(state = {
	data: [],
	loading: false,
	success: undefined,
	error: false
}, action) {
    switch (action.type) {
        case AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function getAssetSensorTypes(  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
}, action) {
    switch (action.type) {
        case AppConstants.GET_ASSET_SENSOR_TYPES:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_ASSET_SENSOR_TYPES_SUCCESS:      
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.GET_ASSET_SENSOR_TYPES_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state;
    }
}

export function getAllAssetCalculatedList(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_ALL_ASSET_CALCULATED_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_ASSET_CALCULATED_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_ASSET_CALCULATED_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export const getFacilityHoliday = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_FACILITY_HOLIDAY:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_FACILITY_HOLIDAY_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_FACILITY_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export const deletePlantAllHoliday = (
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_PLANT_ALL_HOLIDAY:
      return {
        ...state,
        loading: true
      };

    case AppConstants.DELETE_PLANT_ALL_HOLIDAY_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_PLANT_ALL_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response
      };

    case AppConstants.DELETE_PLANT_ALL_HOLIDAY_RESET:
      return {
        ...state,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};

export const getNetworkType = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_TYPE:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_NETWORK_TYPE_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case AppConstants.GET_NETWORK_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export const getDynamicVariableTemplate = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_DYNAMIC_VARIABLE_TEMPLATE:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_DYNAMIC_VARIABLE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const updateDynamicVariableTemplate = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload.response,
        data: [],
        success: false
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export const deleteDynamicVariableTemplate = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload.response.message,
        data: [],
        success: false
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export const getDynamicVariableMapping = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_DYNAMIC_VARIABLE_MAPPING:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_DYNAMIC_VARIABLE_MAPPING_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_DYNAMIC_VARIABLE_MAPPING_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const updateDynamicVariableMapping = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING_FAILURE:
      return {
        ...state,
        error: action.payload.response,
        data: [],
        success: false
      };

    case AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export const deleteDynamicVariableMapping = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export function _getAssetDynamicVariables(
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_ASSET_DYNAMIC_VARIABLES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.GET_ASSET_DYNAMIC_VARIABLES_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.GET_ASSET_DYNAMIC_VARIABLES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
}

export function _getNetworkDynamicVariables(
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_DYNAMIC_VARIABLES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.GET_NETWORK_DYNAMIC_VARIABLES_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.GET_NETWORK_DYNAMIC_VARIABLES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
}
export const getAllAssetAlerts = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_ALL_ASSET_ALERT_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_ASSET_ALERT_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_ASSET_ALERT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const getAllNetworkAlerts = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_ALL_NETWORK_ALERT_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_NETWORK_ALERT_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_NETWORK_ALERT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};


export function createAssetAlert(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.CREATE_ASSET_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_ASSET_ALERT_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_ASSET_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message:
          action.payload &&
          action.payload.response &&
          action.payload.response.message
      };

    case AppConstants.CREATE_ASSET_ALERT_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export function updateAssetAlert(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.UPDATE_ASSET_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_ASSET_ALERT_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_ASSET_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message:
          action.payload &&
          action.payload.response &&
          action.payload.response.message
      };

    case AppConstants.UPDATE_ASSET_ALERT_RESET:
      return {
        ...state,
        loading: false,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export function deleteAssetAlertTemplate(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.DELETE_ASSET_ALERT_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_ASSET_ALERT_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_ASSET_ALERT_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.DELETE_ASSET_ALERT_TEMPLATE_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}


export function deleteNetworkAlertTemplate(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.DELETE_NETWORK_ALERT_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_NETWORK_ALERT_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_NETWORK_ALERT_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.DELETE_NETWORK_ALERT_TEMPLATE_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export function createNetworkAlert(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.CREATE_NETWORK_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_NETWORK_ALERT_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NETWORK_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message:
          action.payload &&
          action.payload.response &&
          action.payload.response.message
      };

    case AppConstants.CREATE_NETWORK_ALERT_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export function updateNetworkAlert(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.UPDATE_NETWORK_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_NETWORK_ALERT_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_NETWORK_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message:
          action.payload &&
          action.payload.response &&
          action.payload.response.message
      };

    case AppConstants.UPDATE_NETWORK_ALERT_RESET:
      return {
        ...state,
        loading: false,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}


export const getAllNetworkList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_ALL_NETWORK_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_ALL_NETWORK_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_ALL_NETWORK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    case AppConstants.GET_ALL_NETWORK_LIST_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};

export function createNetworkTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.CREATE_NETWORK_TEMPLATES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_NETWORK_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NETWORK_TEMPLATES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        message:
          action.payload &&
          action.payload.response &&
          action.payload.response.message
      };

    case AppConstants.CREATE_NETWORK_TEMPLATES_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export const updateNetwork = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.UPDATE_NETWORK:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.UPDATE_NETWORK_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.UPDATE_NETWORK_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.UPDATE_NETWORK_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export function deleteNetwork(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.DELETE_NETWORK:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_NETWORK_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_NETWORK_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.DELETE_NETWORK_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export const getNetworkCalculatedParamTemplate = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_CALCULATED_PARAM_TEMPLATE:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const deleteNetworkCalculatedParamTemplate = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    case AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET:
      return {
        ...state,
        loading: false,
        checkedData: [],
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};

export const getWorkingNonWorkingHoursList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_WORKING_NON_WORKING_HOURS_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_WORKING_NON_WORKING_HOURS_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_WORKING_NON_WORKING_HOURS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const deleteWorkingNonWorkingHoursList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_WORKING_NON_WORKING_HOURS_LIST:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case AppConstants.DELETE_WORKING_NON_WORKING_HOURS_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_WORKING_NON_WORKING_HOURS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const getProductionNonProductionHoursList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.results,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const deleteProductionNonProductionHoursList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case AppConstants.DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const getShiftList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_SHIFT_LIST:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case AppConstants.GET_SHIFT_LIST_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case AppConstants.GET_SHIFT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const deleteShiftList = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.DELETE_SHIFT_LIST:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case AppConstants.DELETE_SHIFT_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.DELETE_SHIFT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export function getNetworkInsightListReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHT_LIST:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      };
    case AppConstants.GET_NETWORK_INSIGHT_LIST_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case AppConstants.GET_NETWORK_INSIGHT_LIST_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export function getDetailsByNetworkInsightIdReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHT_DETAILS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      };
    case AppConstants.GET_NETWORK_INSIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case AppConstants.GET_NETWORK_INSIGHT_DETAILS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export function getNetworkSensorTypesReducer(
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_SENSOR_TYPES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case AppConstants.GET_NETWORK_SENSOR_TYPES_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case AppConstants.GET_NETWORK_SENSOR_TYPES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function getNetworkReadingTypesReducer(
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_NETWORK_READING_TYPES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case AppConstants.GET_NETWORK_READING_TYPES_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case AppConstants.GET_NETWORK_READING_TYPES_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload.response,
        success: false
      };
    default:
      return state;
  }
}

export function getAllActionsOfNetworkReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case AppConstants.GET_ALL_ACTIONS_FOR_NETWORK:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      };
    case AppConstants.GET_ALL_ACTIONS_FOR_NETWORK_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case AppConstants.GET_ALL_ACTIONS_FOR_NETWORK_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export function createManualNetworkInsightReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case AppConstants.CREATE_MANUAL_NETWORK_INSIGHT:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_MANUAL_NETWORK_INSIGHT_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_MANUAL_NETWORK_INSIGHT_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false
      };

    default:
      return state;
  }
}

export const screenDesignReducer = {
    getTemplateList,
    getTemplateDetails,
    getAssetNameDetails,
    updateTemplate,
    deleteTemplate,
    getAllAssetList,
    createTemplate,
    // getMetaDataForm,
    // getListOfAvailableDashboard,
    screenDesignModal,

    addTemplateWidget,
    getTemplateWidgets,
    getWidgetDetailsOfTemplate,
    getGroupWidgetDetailsOfTemplate,
    updateWidgetTemplate,
    deleteWidgetOfTemplate,
    getDefaultTemplateAsset,
    getServiceTemplatePlant,
    getClientTemplate,
    createServiceTemplate,
    updateServiceTemplate,
    deleteServiceTemplate,
    getServiceTemplate,
    getWidgetsDetails,
    createDashboardTemplate,
    getTemplateDashboard,
    addDashboardWidget,
    getDashboardWidgets,
    deleteDashboardWidget,
    updateDashboardWidget,
    editDashboardOrder,
    editWidgetService,
    makeServiceDefault,
		makeServiceDefaultExtended,
    getCalculatedParameterList,
    calculatedParameterModal,
    getAllClientList,
    getAllPlantList,
    deleteCalculatedParameter,
    createCalculatedParameter,
    updateCalculatedParameter,
    verifyTargetSensorParameter,
    assetSensorTypes: getAssetSensorTypes,
    getAllAssetCalculatedList,
    createAssetCalculatedParameter,
    updateAssetCalculatedParameter,
		plantHoliday: getPlantHoliday,
		createPlantHoliday,
		updatePlantHoliday,
		deletePlantHoliday,
		facilityHoliday: getFacilityHoliday,
		deletePlantAllHoliday,
		networkType: getNetworkType,
		createDynamicVariableTemplate,
		dynamicVariableTemplate: getDynamicVariableTemplate,
		updateDynamicVariableTemplate,
		deleteDynamicVariableTemplate,
		createDynamicVariableMapping,
		dynamicVariableMapping: getDynamicVariableMapping,
		updateDynamicVariableMapping,
		deleteDynamicVariableMapping,
		_getAssetDynamicVariables,
    _getNetworkDynamicVariables,
	  getAllAssetAlerts,
    getAllNetworkAlerts,
    createAssetAlert,
    createNetworkAlert,
		updateAssetAlert,
    updateNetworkAlert,
    deleteAssetAlertTemplate,
    deleteNetworkAlertTemplate,
    getAllNetworkList,
    createNetworkTemplate,
    deleteNetwork,
		createNetworkCalculatedParamTemplate,
		networkCalculatedParamTemplate: getNetworkCalculatedParamTemplate,
		updateNetworkCalculatedParamTemplate,
		deleteNetworkCalculatedParamTemplate,
    updateNetwork,
    deleteNetwork,
    getWorkingNonWorkingHoursList,
    deleteWorkingNonWorkingHoursList,
    createUpdateWorkingNonWorking,
    getWorkingNonWorkingHourDetails,
    createUpdateProductionNonProductionHour,
    getProductionNonProductionHourDetails,
    getProductionNonProductionHoursList,
    deleteProductionNonProductionHoursList,
    getShiftList,
    deleteShiftList,
    createUpdateShift,
    getShiftDetails,
    getNetworkInsightListReducer,
    getDetailsByNetworkInsightIdReducer,
    getNetworkSensorTypesReducer,
    getAllActionsOfNetworkReducer,
    getNetworkReadingTypesReducer,
    createManualNetworkInsightReducer
}