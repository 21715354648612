import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  httpMethodGetJSON,
  httpMethodPatch,
  httpMethodDelete,
  httpMethodPost,
  httpMethodPut
} from '../../../https';
import {
  OVERVIEW_WIDGET_DATA,
  HEATMAP_WIDGET_DATA,
  HEATMAP_GRAPH_DATA,
  OVERVIEW_READING_LIVE,
  OVERVIEW_READING_DATA,
  PIECHART_WIDGET_DATA,
  HEALTH_WIDGET_DATA,
  INSIGHT_WIDGET_DATA,
  CYCLE_DISTRIBUTION_WIDGET_DATA,
  ANOMALY_WIDGET_DATA,
  GET_ANOMALY_IMPACT_DATA,
  DIGITBOX1_WIDGET_DATA,
  DIGITBOX2_WIDGET_DATA,
  DIGITBOX2_LIVE_WIDGET_DATA,
  DIGITBOX2_TREND_WIDGET_DATA,
  DIGITBOX2_COST_WIDGET_DATA,
  DIGITBOX3_WIDGET_DATA,
  DIGITBOX3_SERIES_WIDGET_DATA,
  PARAM_PERF_WIDGET_DATA,
  PARAM_PERF_SERIES_DATA,
  RUN_HOUR_SERIES_DATA,
  RUN_HOUR_COMPUTED_DATA,
  GET_ALL_TREES,
  GET_TREE_DATA,
  CREATE_TREE_DATA,
  CREATE_NODE_DATA,
  DELETE_NODE,
  GET_TREE_SENSOR_HEALTH_DATA,
  GRAPH_COMPUTED_DATA,
  GRAPH_NORMAL_DATA,
  GRAPH_READING_SERIES,
  ALERT_LOG_DATA,
  ALARM_DATA,
  DIAL_LIVE_WIDGET_DATA,
  DIAL_COMPUTED_WIDGET_DATA,
  LIVETABLE_WIDGET_DATA,
  TABLE_WIDGET_DATA,
  GET_GIS_SENSOR_WISE_DATA,
  EDIT_NODE_DATA,
  UPLOAD_IMAGE_CONFIGURATION,
  GET_UPLOAD_IMAGE_WIDGET_DATA,
  DIGITBOX2_COST_SERIES_WIDGET_DATA,
  GET_LIST_VIEW_PARAM_DATA,
  GET_PARAM_SUMMARY_DATA,
  GET_ASSET_HISTORY,
  GET_ASSET_INSIGHT_PARAMS,
  GET_ASSET_OPEN_INSIGHTS,
  GET_ASSET_INSIGHTS,
  GET_ASSET_INSIGHTS_CONFIG,
  SUBMIT_ASSET_FEEDBACK,
  GET_INSIGHT_GRAPH_DATA,
  GET_PARAM_META_DATA,
  DIGITBOX4_WIDGET_DATA,
  GET_FACTORY_DATA,
  GET_FACTORY_INFOWINDOW_DATA,
  GET_INSIGHTS_ALERTS_DATA,
  ADD_SELECTED_INSIGHTS_ALERT,
  ADD_SELECTED_INSIGHTS_ALERT_SUCCESS,
  OPEN_CRITICAL_EVENT,
  OPEN_CRITICAL_EVENT_SUCCESS,
  GET_WELLNESS_DATA,
  GET_ALERTS_WIDGET_DATA,
  UPDATE_ALERTS_WIDGET_STATUS,
  GET_ASSET_INSIGHT_ACTION,
  GET_ALERTS_WIDGET_TABLEDATA,
  GET_ALERTS_WIDGET_GRAPH_DATA,
  GET_WIDGET_LIST_FROM_TEMPLATE,
  ANALYZE_DATA,
  GET_TAG_DATA,
  GET_CXO_SUMMARY,
  GET_TAG_TIMESERIES_DATA,
  GRAPH_PARAMETER_DATA,
  SCORECARD_WIDGET_DATA,
  GET_TAG_TIMESERIES_COMPUTED_DATA,
  GET_FACILITY_DATA,
  GET_INSIGHT_IMPACT_CONFIG,
  UPDATE_INSIGHT_IMPACT_CONFIG,
  GET_TAG_READING_DATA,
  ARCH_CHART_WIDGET_DATA,
  GET_CONFIGURED_ALERT_COUNT,
  INFLUNCERS_DATA,
  GET_ALL_INSIGHT_FOR_GROUP
} from './widgetConstants';

import { URL, URL_V1 } from '../../../constants';
import {
  getAlertConnection,
  getInsightConnection,
  getActivityConnection,
  getEventConfiguration,
  addUserActivity,
  getAssetHistoryTimelineData,
  getNetworkHistoryTimelineData,
  getAssetHistoryTimelineLogData,
  getAssetSensor
} from './AssetHistory/epic';

import {
  getInsightAlertSummaryData,
  getInsightAlertSummaryCbmAssetData,
  getInsightAlertSummaryAssetsMinData
} from './AssetInsightAlert/epic';
import {
  getInsightTimelineAssetHistoryData,
  getInsightTimelineActivityData,
  getInsightTimelineCbmAssetData,
  getInsightTimelineAlertLogData,
  getInsightTimelineInsightData,
  getInsightTimelineAssetsMinData
} from './AssetEventLog/epic';
import {
  getAssetHealthCountCbmAssetData,
  getAssetHealthCountAssetsData
} from './AssetHealthCount/epic';
import {
  getGisViewReadingData,
  getKpiViewData,
  getKpiPlantData,
  getKpiViewReadingData,
  getKpiRedirectionDashboard
} from './GISView/epic';
import {
  getUsecaseSummaryCbmAssetData,
  getUsecaseSummaryAllAssetsListData,
  getUsecaseSummaryAssetsData,
  getUsecaseSummaryAlertLogData,
  getUsecaseSummaryInsightData,
  getUsecaseSummaryTagData,
  getUsecaseSummaryImpactData
} from './UsecaseSummary/epic';
import {
  getAssetDetailListCbmAssetData,
  getAssetDetailListAssetsData,
  getAssetCbmAssetNamesMember
} from './AssetDetailList/epic';

import {
  getWorkplaceGraphNormal,
  getWorkplaceGraphComputed
} from './WorkplaceGraph/epic';

import {
  getNetworkDetailsData,
  deleteNetworkNode,
  updateNetworkNode,
  getNetworkParameters,
  getNodeParameters,
  getNodeConfig,
  createNodeParameters,
  createNodeConfig,
  setNetworkMainNode,
  getNodeSubtree,
  getNetworkNodeLocationConfig,
  updateNetworkNodeLocationConfig,
  getNetworkAssetCbmAsset,
  getNetworkAssetMeta
} from './NetworkView/epic';

import {
  getAssetCbmTopContributors,
  getInsightsByPlantId
} from './AssetTopContributors/epic';

import {
  getAssetCbmInsightOccurrence,
  getNetworkInsightsOccurence
} from './KpiTracker/epic';

import { getNetworkInsights } from './GroupWidget/epic';

import {
  getNetworkInsightsOccurrence,
  getNetworkInsightsActions,
  createNetworkInsightsAction
} from './InsightGraph/epic';

const SERIES_URL = `${URL}/reading/reading_series/`;
const TIME_SERIES_URL = `${URL}/reading/reading_series/`;
const READING_LIVE_URL = `${URL}/reading/reading_live/`;
const READING_DATA_URL = `${URL}/reading/reading_data/`;
const READING_COMPUTED_LIVE_URL = `${URL}/reading/reading_computed_live/`;
const HEALTH_DATA_URL = `${URL}/reading/sensor/health/`;
// const HEALTH_DATA_URL = `${URL_V1}/sensor/health/`;
const INSIGHT_DATA_URL = `${URL}/asset_insights/stats/`;
// const INSIGHT_DATA_URL = `${URL_V1}/asset_insights/stats/`;
const CYCLE_DISTRIBUTION_DATA_URL = `${URL}/reading/cycle-distribution/`;
// const CYCLE_DISTRIBUTION_DATA_URL = `${URL_V1}/get_cycle_distribution/`;
// const ANOMALY_DATA_URL = `${URL_V1}/asset_insights/anomaly_detection/`;
// const ANOMALY_DATA_URL = `${URL}/asset_insights/anomaly_detection/`;
//const ANOMALY_DATA_URL = `https://api.smartsen.se/api/v2/asset_insights/anomaly_detection/`;
//const ANOMALY_DATA_URL = `${URL}/anom/`;
const ANOMALY_DATA_TAG_URL = `${URL}/asset_insights/anomaly_detection/`;
const ANOMALY_IMPACT_DATA_URL = `${URL}/asset_insights/anomaly_impact_values/`;
const ANOMALY_DATA_WH_NWH_URL = `${URL}/asset_insights/anomaly_working_hours/`;
const HEATMAP_SERIES_URL = `${URL}/reading/reading_series/`;
const ARCH_SCORE_URL = `${URL}/asset-cbm/eco_score/`;
// const UNIT_COST_URL = `${URL_V1}/unitcost/`;
const UNIT_COST_URL = `${URL}/reading/unitcost/`;
const PARAM_PERF_URL = `${URL}/sensor/geo_summary/`;
const READING_COMPUTED_URL = `${URL}/reading/reading_computed/`;
const READING_COMPUTED_DATA_URL = `${URL}/reading/reading_computed_data/`;
const ALERT_LOG_URL = `${URL}/alert-logs/`;
const ALARM_URL = `${URL}/reading/reading_series/`;
const getAlertsApi = (assetId) => `${URL}/alerts/asset/${assetId}/alert-logs/`;
const getInsightsApi = (assetId) =>
  `${URL}/asset-cbm/assets/${assetId}/overview/`;
const getAlertsWidgetApi = (assetId) => `${URL}/alerts/asset/${assetId}`;
const ALERT_WIDGET_DATA = `${URL}/alert-logs/`;
const ALERT_WIDGET_GRAPH_DATA = `${URL}/reading/reading_series/`;
const GRAPH_READING_SERIES_URL = `${URL}/reading/reading_series/`;
const TAG_URL = `${URL}/tags/details/`;
const FACILITY_URL = `${URL}/plants/`;
const getInsightImpactConfigUrl = (assetId, assetAlgoId) =>
  `${URL}/asset-cbm/impact/asset/${assetId}/insight/${assetAlgoId}/config/`;

export const getOverviewData = (action$) =>
  action$.pipe(
    ofType(OVERVIEW_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getOverviewReadingData = (action$) =>
  action$.pipe(
    ofType(OVERVIEW_READING_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getOverviewReadingLive = (action$) =>
  action$.pipe(
    ofType(OVERVIEW_READING_LIVE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_LIVE_URL);
    })
  );

export const getDialLiveData = (action$) =>
  action$.pipe(
    ofType(DIAL_LIVE_WIDGET_DATA),
    mergeMap((action) => {
      return action.payload.isComputed
        ? httpMethodPost(action, READING_COMPUTED_LIVE_URL)
        : httpMethodGetJSON(action, READING_LIVE_URL);
    })
  );

export const getDialComputedData = (action$) =>
  action$.pipe(
    ofType(DIAL_COMPUTED_WIDGET_DATA),
    mergeMap((action) => {
      return action.payload.isComputed
        ? httpMethodPost(action, READING_COMPUTED_DATA_URL)
        : httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getHealthData = (action$) =>
  action$.pipe(
    ofType(HEALTH_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, HEALTH_DATA_URL);
    })
  );

export const getInsightData = (action$) =>
  action$.pipe(
    ofType(INSIGHT_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, INSIGHT_DATA_URL);
    })
  );

export const getCycleDistributionData = (action$) =>
  action$.pipe(
    ofType(CYCLE_DISTRIBUTION_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, CYCLE_DISTRIBUTION_DATA_URL);
    })
  );

export const getAnomalyData = (action$) =>
  action$.pipe(
    ofType(ANOMALY_WIDGET_DATA),
    mergeMap((action) => {
      const url =
        action.payload &&
        action.payload.working_hrs_start_time &&
        action.payload.working_hrs_end_time
          ? ANOMALY_DATA_WH_NWH_URL
          : ANOMALY_DATA_TAG_URL;

      return httpMethodGetJSON(action, url);
    })
  );

export const getAnomalyImpactData = (action$) => {
  return action$.pipe(
    ofType(GET_ANOMALY_IMPACT_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ANOMALY_IMPACT_DATA_URL);
    })
  );
};

export const getHeatmapData = (action$) =>
  action$.pipe(
    ofType(HEATMAP_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, HEATMAP_SERIES_URL);
    })
  );

export const getHeatmapGraphData = (action$) =>
  action$.pipe(
    ofType(HEATMAP_GRAPH_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, HEATMAP_SERIES_URL);
    })
  );

export const getPieChartData = (action$) =>
  action$.pipe(
    ofType(PIECHART_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
        // return httpMethodPost(action, `${URL}/reading_computed_data/`);
      }
      return httpMethodGetJSON(action, `${READING_DATA_URL}`);
    })
  );

export const getArchChartData = (action$) =>
  action$.pipe(
    ofType(ARCH_CHART_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ARCH_SCORE_URL);
    })
  );

export const getFactoryData = (action$) =>
  action$.pipe(
    ofType(GET_FACTORY_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
      }
      return httpMethodGetJSON(action, `${READING_DATA_URL}`);
    })
  );

export const getFactoryInfoWindowData = (action$) =>
  action$.pipe(
    ofType(GET_FACTORY_INFOWINDOW_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
      }
      return httpMethodGetJSON(action, `${READING_DATA_URL}`);
    })
  );

export const getInsightsAlertsData = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHTS_ALERTS_DATA),
    mergeMap((action) => {
      if (action.payload.assetId) {
        if (action.payload && action.payload.type === 'alert') {
          return httpMethodGetJSON(
            action,
            getAlertsApi(action.payload.assetId)
          );
        } else {
          return httpMethodGetJSON(
            action,
            getInsightsApi(action.payload.assetId)
          );
        }
      }
    })
  );

export const addSelectedInsightsAlert = (action$) =>
  action$.pipe(
    ofType(ADD_SELECTED_INSIGHTS_ALERT),
    mergeMap((action) => {
      return of({
        type: ADD_SELECTED_INSIGHTS_ALERT_SUCCESS,
        payload: action.payload
      });
    })
  );

export const getOpenCriticalEvents = (action$) =>
  action$.pipe(
    ofType(OPEN_CRITICAL_EVENT),
    mergeMap((action) => {
      return of({ type: OPEN_CRITICAL_EVENT_SUCCESS, payload: action.payload });
    })
  );

export const getAlertsWidgetData = (action$) =>
  action$.pipe(
    ofType(GET_ALERTS_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload && action.payload.assetId) {
        return httpMethodGetJSON(
          action,
          getAlertsWidgetApi(action.payload.assetId)
        );
      }
    })
  );

export const getAlertsWidgetTableData = (action$) =>
  action$.pipe(
    ofType(GET_ALERTS_WIDGET_TABLEDATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ALERT_WIDGET_DATA);
    })
  );

export const getAlertsWidgetGraphData = (action$) =>
  action$.pipe(
    ofType(GET_ALERTS_WIDGET_GRAPH_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ALERT_WIDGET_GRAPH_DATA);
    })
  );

export const getWellnessData = (action$) =>
  action$.pipe(
    ofType(GET_WELLNESS_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
      }
      return httpMethodGetJSON(action, `${READING_DATA_URL}`);
    })
  );

export const updateAlertWidgetStatus = (action$) =>
  action$.pipe(
    ofType(UPDATE_ALERTS_WIDGET_STATUS),
    mergeMap((action) => {
      return httpMethodPatch(action, `${URL}/alert-logs/`);
    })
  );

export const getDigitbox1Data = (action$) =>
  action$.pipe(
    ofType(DIGITBOX1_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isTag) {
        return httpMethodGetJSON(action, `${URL}/reading/reading_series/`);
      }

      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
        // return httpMethodPost(action, `${URL}/reading_computed_data/`);
      }
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getDigitbox2Data = (action$) =>
  action$.pipe(
    ofType(DIGITBOX2_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
        // return httpMethodPost(action, `${URL}/reading_computed_data/`);
      }
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getDigitbox2Live = (action$) =>
  action$.pipe(
    ofType(DIGITBOX2_LIVE_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, `${READING_COMPUTED_LIVE_URL}`);
      }
      return httpMethodGetJSON(action, READING_LIVE_URL);
    })
  );

export const getDigitbox2Trend = (action$) =>
  action$.pipe(
    ofType(DIGITBOX2_TREND_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getDigitbox2Cost = (action$) =>
  action$.pipe(
    ofType(DIGITBOX2_COST_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, UNIT_COST_URL);
    })
  );

export const getDigitbox2CostSeries = (action$) =>
  action$.pipe(
    ofType(DIGITBOX2_COST_SERIES_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getDigitbox3Data = (action$) =>
  action$.pipe(
    ofType(DIGITBOX3_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
        // return httpMethodPost(action, `${URL}/reading_computed_data/`);
      }
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getDigitbox3DataSeries = (action$) =>
  action$.pipe(
    ofType(DIGITBOX3_SERIES_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, `${READING_COMPUTED_URL}`);
      }
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getDigitbox4Data = (action$) =>
  action$.pipe(
    ofType(DIGITBOX4_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getParamPerfData = (action$) =>
  action$.pipe(
    ofType(PARAM_PERF_WIDGET_DATA),
    mergeMap((action) => {
      let wid = action.payload.widgetId;
      return httpMethodGetJSON(action, PARAM_PERF_URL + wid + '/');
    })
  );

export const getParamPerfSeriesData = (action$) =>
  action$.pipe(
    ofType(PARAM_PERF_SERIES_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getRunHourSeriesData = (action$) =>
  action$.pipe(
    ofType(RUN_HOUR_SERIES_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getRunHourComputedData = (action$) =>
  action$.pipe(
    ofType(RUN_HOUR_COMPUTED_DATA),
    mergeMap((action) => {
      return httpMethodPost(action, READING_COMPUTED_URL);
    })
  );

export const getGraphComputedData = (action$) =>
  action$.pipe(
    ofType(GRAPH_COMPUTED_DATA),
    mergeMap((action) => {
      return httpMethodPost(action, READING_COMPUTED_URL);
    })
  );

export const getGraphNormalData = (action$) =>
  action$.pipe(
    ofType(GRAPH_NORMAL_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getGraphReadingData = (action$) =>
  action$.pipe(
    ofType(GRAPH_READING_SERIES),
    mergeMap((action) => {
      return httpMethodGetJSON(action, GRAPH_READING_SERIES_URL);
    })
  );

export const getAlertLogData = (action$) =>
  action$.pipe(
    ofType(ALERT_LOG_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ALERT_LOG_URL);
    })
  );

export const getAlarmData = (action$) =>
  action$.pipe(
    ofType(ALARM_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ALARM_URL);
    })
  );

export const getLiveTableData = (action$) =>
  action$.pipe(
    ofType(LIVETABLE_WIDGET_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${READING_LIVE_URL}`);
    })
  );

export const getTableData = (action$) =>
  action$.pipe(
    ofType(TABLE_WIDGET_DATA),
    mergeMap((action) => {
      if (
        action.payload.isLive === true &&
        action.payload.isComputed !== true
      ) {
        return httpMethodGetJSON(action, `${READING_LIVE_URL}`);
      }

      if (
        action.payload.isLive === true &&
        action.payload.isComputed === true
      ) {
        return httpMethodPost(action, `${READING_COMPUTED_LIVE_URL}`);
      }

      if (
        action.payload.isLive === false &&
        action.payload.isComputed !== true
      ) {
        return httpMethodGetJSON(action, `${READING_DATA_URL}`);
      }

      if (
        action.payload.isLive === false &&
        action.payload.isComputed === true
      ) {
        return httpMethodPost(action, `${READING_COMPUTED_DATA_URL}`);
      }
    })
  );

export const getAllTrees = (action$) =>
  action$.pipe(
    ofType(GET_ALL_TREES),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/member/tree/`);
    })
  );

export const getTreeData = (action$) =>
  action$.pipe(
    ofType(GET_TREE_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/member/tree/${action.payload.treeId}/reading/`
      );
    })
  );

export const createTree = (action$) =>
  action$.pipe(
    ofType(CREATE_TREE_DATA),
    mergeMap((action) => {
      let token = `token:${JSON.parse(
        localStorage.getItem('smartsense.authToken')
      )}`;

      let header = {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
      return httpMethodPost(action, `${URL}/member/tree/`, header);
    })
  );

export const createTreeNode = (action$) =>
  action$.pipe(
    ofType(CREATE_NODE_DATA),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/member/tree/map/`);
    })
  );

export const editTreeNode = (action$) =>
  action$.pipe(
    ofType(EDIT_NODE_DATA),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/member/tree/${action.payload.id}/map/`
      );
    })
  );

export const deleteTreeNode = (action$) =>
  action$.pipe(
    ofType(DELETE_NODE),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/member/tree/${action.payload.treeId}/map/`
      );
    })
  );

export const getTreeSensorHealth = (action$) =>
  action$.pipe(
    ofType(GET_TREE_SENSOR_HEALTH_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/reading/sensor/health/`);
    })
  );

export const getGisSensorWiseData = (action$) =>
  action$.pipe(
    ofType(GET_GIS_SENSOR_WISE_DATA),
    mergeMap((action) => {
      let wid = action.payload.widgetId;
      return httpMethodGetJSON(action, `${URL}/sensor/geo_summary/${wid}/`);
    })
  );

export const uploadImageConfiguration = (action$) =>
  action$.pipe(
    ofType(UPLOAD_IMAGE_CONFIGURATION),
    mergeMap((action) => {
      let token = `token:${JSON.parse(
        localStorage.getItem('smartsense.authToken')
      )}`;
      const formData = new FormData();
      formData.append('file', action.payload.file);

      let param = {};
      param.payload = formData;
      param.type = action.type;

      return httpMethodPost(param, `${URL}/media_handler/`, {
        Authorization: token
      });
    })
  );

export const getUploadImageWidgetData = (action$) =>
  action$.pipe(
    ofType(GET_UPLOAD_IMAGE_WIDGET_DATA),
    mergeMap((action) => {
      let token = `token:${JSON.parse(
        localStorage.getItem('smartsense.authToken')
      )}`;

      let header = {
        Authorization: token,
        'Content-Type': 'application/json;charset=UTF-8'
      };

      if (
        action.payload.isLive === true &&
        action.payload.isComputed !== true
      ) {
        return httpMethodGetJSON(action, `${READING_LIVE_URL}`, header);
      }

      if (
        action.payload.isLive === true &&
        action.payload.isComputed === true
      ) {
        return httpMethodPost(action, `${READING_COMPUTED_LIVE_URL}`, header);
      }

      if (
        action.payload.isLive === false &&
        action.payload.isComputed !== true
      ) {
        return httpMethodGetJSON(action, `${READING_DATA_URL}`, header);
      }

      if (
        action.payload.isLive === false &&
        action.payload.isComputed === true
      ) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL, header);
        // return httpMethodPost(action, `${URL}/reading_computed_data/`, header);
      }
    })
  );

export const getListViewParamData = (action$) =>
  action$.pipe(
    ofType(GET_LIST_VIEW_PARAM_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getParamSummaryData = (action$) =>
  action$.pipe(
    ofType(GET_PARAM_SUMMARY_DATA),
    mergeMap((action) => {
      let token = `token:${JSON.parse(
        localStorage.getItem('smartsense.authToken')
      )}`;

      let header = {
        Authorization: token,
        'Content-Type': 'application/json;charset=UTF-8'
      };

      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_URL);
      }

      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getParamSummaryMetaData = (action$) =>
  action$.pipe(
    ofType(GET_PARAM_META_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getAssetHistory = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_HISTORY),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/timelines/`);
    })
  );

export const getAssetInsightParamList = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_INSIGHT_PARAMS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/${action.payload.assetType}/insights/config/`
      );
    })
  );

export const getAssetOpenInsight = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_OPEN_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/insights/`);
    })
  );

export const getAssetInsight = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/insights/`);
    })
  );

export const getAssetInsightConfig = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_INSIGHTS_CONFIG),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/${action.payload.assetType}/insights/config/${action.payload.id}/`
      );
    })
  );

export const submitAssetFeedback = (action$) =>
  action$.pipe(
    ofType(SUBMIT_ASSET_FEEDBACK),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/asset-cbm/assets/feedbacks/`);
    })
  );

export const getInsightGraphData = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHT_GRAPH_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${TIME_SERIES_URL}`);
    })
  );

export const getInfluencersData = (action$) =>
  action$.pipe(
    ofType(INFLUNCERS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${TIME_SERIES_URL}`);
    })
  );

export const getAnalyseData = (action$) =>
  action$.pipe(
    ofType(ANALYZE_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, SERIES_URL);
    })
  );

export const getAssetInsightAction = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_INSIGHT_ACTION),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/insight/actions/${action.payload.id}/`
      );
    })
  );

export const getWidgetListFromTemplateId = (action$) =>
  action$.pipe(
    ofType(GET_WIDGET_LIST_FROM_TEMPLATE),
    mergeMap((action) => {
      const { templateId } = action.payload;
      if (templateId) {
        return httpMethodGetJSON(
          action,
          `${URL}/templates/${templateId}/widgets/`
        );
      }
    })
  );

export const getGraphParameterData = (action$) =>
  action$.pipe(
    ofType(GRAPH_PARAMETER_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, SERIES_URL);
    })
  );

export const getTagData = (action$) =>
  action$.pipe(
    ofType(GET_TAG_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TAG_URL);
    })
  );

export const getCxoSummary = (action$) =>
  action$.pipe(
    ofType(GET_CXO_SUMMARY),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, `${READING_COMPUTED_URL}`);
      }
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getTagTimeseriesData = (action$) =>
  action$.pipe(
    ofType(GET_TAG_TIMESERIES_DATA),
    mergeMap((action) => {
      if (
        (action.payload.boxType === 'Digitbox 2' &&
          (action.payload.subType === 'value' ||
            action.payload.subType === 'trend')) ||
        action.payload.boxType == 'Digitbox 3' ||
        action.payload.boxType == 'Digitbox 4'
      ) {
        return httpMethodGetJSON(action, TIME_SERIES_URL);
      } else {
        return httpMethodGetJSON(action, READING_DATA_URL);
      }
    })
  );

export const getScoreCardData = (action$) =>
  action$.pipe(
    ofType(SCORECARD_WIDGET_DATA),
    mergeMap((action) => {
      if (action.payload.isComputed === true) {
        return httpMethodPost(action, READING_COMPUTED_DATA_URL);
      }
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );
export const getTagTimeseriesComputedData = (action$) =>
  action$.pipe(
    ofType(GET_TAG_TIMESERIES_COMPUTED_DATA),
    mergeMap((action) => {
      return httpMethodPost(action, READING_COMPUTED_URL);
    })
  );

export const getFacilityData = (action$) =>
  action$.pipe(
    ofType(GET_FACILITY_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, FACILITY_URL);
    })
  );

export const getInsightImpactConfig = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHT_IMPACT_CONFIG),
    mergeMap((action) => {
      const { assetId, assetAlgoId } = action.payload;
      return httpMethodGetJSON(
        action,
        getInsightImpactConfigUrl(assetId, assetAlgoId)
      );
    })
  );

export const updateInsightImpactConfig = (action$) =>
  action$.pipe(
    ofType(UPDATE_INSIGHT_IMPACT_CONFIG),
    mergeMap((action) => {
      const { assetId, assetAlgoId } = action.payload;
      return httpMethodPost(
        action,
        getInsightImpactConfigUrl(assetId, assetAlgoId)
      );
    })
  );

export const getTagReadingData = (action$) => {
  return action$.pipe(
    ofType(GET_TAG_READING_DATA),
    mergeMap((action) => {
      return action.payload.isComputed
        ? httpMethodPost(action, READING_COMPUTED_URL)
        : httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );
};

export const getConfiguredAlertCount = (action$) =>
  action$.pipe(
    ofType(GET_CONFIGURED_ALERT_COUNT),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/alerts/summary/`);
    })
  );

export const getAllInsightForGroup = (action$) =>
  action$.pipe(
    ofType(GET_ALL_INSIGHT_FOR_GROUP),
    mergeMap((action) => {
      const { assetType, ...restPayload } = action.payload;
      return httpMethodGetJSON(
        { ...action, payload: restPayload }, 
        `${URL}/asset-cbm/asset-names/${assetType}/all-insights/config/`
      );
    })
  );

export const dashboardWidgetEpics = {
  getOverviewData,
  getOverviewReadingData,
  getOverviewReadingLive,
  getDialLiveData,
  getDialComputedData,
  getHealthData,
  getInsightData,
  getHeatmapData,
  getHeatmapGraphData,
  getPieChartData,
  getArchChartData,
  getFactoryData,
  getFactoryInfoWindowData,
  getInsightsAlertsData,
  addSelectedInsightsAlert,
  getOpenCriticalEvents,
  getAlertsWidgetData,
  getAlertsWidgetTableData,
  getAlertsWidgetGraphData,
  getWellnessData,
  updateAlertWidgetStatus,
  getCycleDistributionData,
  getAnomalyData,
  getAnomalyImpactData,
  getDigitbox1Data,
  getDigitbox2Data,
  getDigitbox2Live,
  getDigitbox2Trend,
  getDigitbox2Cost,
  getDigitbox2CostSeries,
  getDigitbox3Data,
  getDigitbox3DataSeries,
  getParamPerfData,
  getParamPerfSeriesData,
  getRunHourSeriesData,
  getRunHourComputedData,
  getGraphComputedData,
  getGraphNormalData,
  getGraphReadingData,
  getAlertLogData,
  getAlarmData,
  getLiveTableData,
  getTableData,
  getAllTrees,
  getTreeData,
  createTree,
  createTreeNode,
  editTreeNode,
  deleteTreeNode,
  getTreeSensorHealth,
  getGisSensorWiseData,
  uploadImageConfiguration,
  getUploadImageWidgetData,
  getListViewParamData,
  getParamSummaryData,
  getParamSummaryMetaData,
  getAssetHistory,
  getAssetInsightParamList,
  getAssetOpenInsight,
  getAssetInsight,
  getAssetInsightConfig,
  submitAssetFeedback,
  getInsightGraphData,
  getDigitbox4Data,
  getAlertConnection,
  getInsightConnection,
  getActivityConnection,
  getEventConfiguration,
  addUserActivity,
  getAssetHistoryTimelineData,
  getNetworkHistoryTimelineData,
  getAssetHistoryTimelineLogData,
  getAssetSensor,
  getAssetInsightAction,
  getWidgetListFromTemplateId,
  getAnalyseData,
  getInsightAlertSummaryData,
  getInsightAlertSummaryCbmAssetData,
  getInsightAlertSummaryAssetsMinData,
  getInsightTimelineAssetHistoryData,
  getInsightTimelineActivityData,
  getInsightTimelineCbmAssetData,
  getInsightTimelineAlertLogData,
  getInsightTimelineInsightData,
  getInsightTimelineAssetsMinData,
  getAssetHealthCountCbmAssetData,
  getAssetHealthCountAssetsData,
  getInsightsByPlantId,
  getGisViewReadingData,
  getKpiViewData,
  getKpiPlantData,
  getKpiViewReadingData,
  getKpiRedirectionDashboard,
  getGraphParameterData,
  getUsecaseSummaryCbmAssetData,
  getUsecaseSummaryAllAssetsListData,
  getUsecaseSummaryAssetsData,
  getUsecaseSummaryAlertLogData,
  getUsecaseSummaryInsightData,
  getUsecaseSummaryTagData,
  getUsecaseSummaryImpactData,
  getTagData,
  getCxoSummary,
  getAssetDetailListCbmAssetData,
  getAssetDetailListAssetsData,
  getTagTimeseriesData,
  getScoreCardData,
  getTagTimeseriesComputedData,
  getFacilityData,
  getInsightImpactConfig,
  updateInsightImpactConfig,
  getWorkplaceGraphNormal,
  getWorkplaceGraphComputed,
  getTagReadingData,
  getConfiguredAlertCount,
  getNetworkDetailsData,
  deleteNetworkNode,
  updateNetworkNode,
  getNetworkParameters,
  getNodeParameters,
  getNodeConfig,
  createNodeParameters,
  createNodeConfig,
  setNetworkMainNode,
  getNodeSubtree,
  getNetworkNodeLocationConfig,
  updateNetworkNodeLocationConfig,
  getNetworkAssetCbmAsset,
  getNetworkAssetMeta,
  getAssetCbmAssetNamesMember,
  getAssetCbmTopContributors,
  getAssetCbmInsightOccurrence,
  getNetworkInsightsOccurence,
  getInfluencersData,
  getAllInsightForGroup,
  getNetworkInsights,
  getNetworkInsightsOccurrence,
  getNetworkInsightsActions,
  createNetworkInsightsAction
}