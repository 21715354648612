// import Moment from 'react-moment';
import moment from 'moment';
// import 'moment-timezone';

const lastMonthsGet = (from, to, months) => {
  const now = moment();

  return [
    now.clone().subtract(months * from, 'months'),
    now
      .clone()
      .add(months * to, 'months')
      .endOf('months')
  ];
};

const momentHourMinuteSet = (date, time) => {
  const [hours, minutes] = time.split(/:/);

  return date.clone().set('hours', hours).set('minutes', minutes);
};

export default class DateTime {
  // L	04/09/1986	Date(in local format)
  // LL	September 4 1986	Month name, day of month, year
  // LLL	September 4 1986 8: 30 PM	Month name, day of month, year, time
  // LLLL	Thursday, September 4 1986 8: 30 PM	Day of week, month name, day of month, year, time
  // LT	08: 30 PM	Time(without seconds)
  // LTS	08: 30: 00 PM	Time(with seconds)

  static widgetDateRangeGet = (_period, _from, startTime, _to, endTime) => {
    const period = _period.toLowerCase();

    const from = parseInt(_from);

    const to = parseInt(_to);

    const now = moment();

    const [startDate, endDate] = (() => {
      switch (period) {
        case 'day':
          return [
            now.clone().subtract(from, 'days').startOf('days'),
            now.clone().add(to, 'days').endOf('days')
          ];

        case 'week':
          return [
            now.clone().subtract(from, 'isoWeeks').startOf('isoWeeks'),
            now.clone().add(to, 'isoWeeks').endOf('isoWeeks')
          ];

        case 'month':
          return [
            now.clone().subtract(from, 'months').startOf('months'),
            now.clone().add(to, 'months').endOf('months')
          ];

        case 'last month':
        case 'last 1 month':
          return lastMonthsGet(from, to, 1);

        case 'last 3 months':
          return lastMonthsGet(from, to, 3);

        case 'last 6 months':
          return lastMonthsGet(from, to, 6);

        case 'last 9 months':
          return lastMonthsGet(from, to, 9);

        case 'year':
          return [
            now.clone().subtract(from, 'years').startOf('years'),
            now.clone().add(to, 'years').endOf('years')
          ];
      }
    })();

    return [
      momentHourMinuteSet(startDate, startTime || '00:00').format(),
      momentHourMinuteSet(endDate, endTime || '23:59').format()
    ];
  };

  static dashoardDateRangeGet = (dateRangeString) => {
    const now = moment();

    const [startDate, endDate] = (() => {
      const monthLast = (() => {
        const startDate = now.clone().subtract(1, 'months').startOf('months');

        return [startDate, startDate.clone().endOf('months')];
      })();

      const dayBefore = now.clone().subtract(1, 'days').endOf('days');

      switch (dateRangeString) {
        case 'Today':
          return [now.clone().startOf('days'), now.clone().endOf('days')];

        case 'Current Week':
          return [
            now.clone().startOf('isoWeeks'),
            now.clone().endOf('isoWeeks')
          ];

        case 'Last Week':
          return (() => {
            const startDate = now
              .clone()
              .subtract(1, 'isoWeeks')
              .startOf('isoWeeks');

            return [startDate, startDate.clone().endOf('isoWeeks')];
          })();

        case 'Current Month':
          return [now.clone().startOf('months'), now.clone().endOf('months')];

        case 'Last Month':
          return monthLast;

        case 'Current Qtr.':
          return [
            now.clone().startOf('quarters'),
            now.clone().endOf('quarters')
          ];

        case 'Last 6 Month':
          return [now.clone().subtract(6, 'months').startOf('days'), dayBefore];

        case 'Last 9 Month':
          return [now.clone().subtract(9, 'months').startOf('days'), dayBefore];

        case 'Current Year':
          return [now.clone().startOf('years'), now.clone().endOf('years')];

        case 'Current FY year':
          return (() => {
            const startDate =
              now.month() < 3
                ? now.clone().month(3).startOf('months').subtract(1, 'years')
                : now.clone().month(3).startOf('months');

            return [startDate, startDate.clone().add(1, 'years')];
          })();

        default:
          return monthLast;
      }
    })();

    return [startDate.format(), endDate.format()];
  };

  static formatDefault = (date) => {

    return moment(date).format();
  }
  static format = (date, format) => {
    return moment(date).format(format);
  };

  static utcFormat = (date, format) => {
    return moment(date).utc().format(format);
  };

  static UnixFormat = (date)=>{
    return moment(date).unix();
  }

  static unixtoDateFormat = (unixData, format="YYYY-MM-DD")=>{
    return moment.unix(unixData).utc().format(format);
  }

  static unixtoDate = (unixData, format="YYYY-MM-DD")=>{
    return moment.unix(unixData).format(format);
  }
  static today = (format) => {
    return moment().format(format);
  };

  static fromnow = (date) => {

    return moment(date).fromNow();
  };

  static isValid = (date) => {
    return moment(date).isValid();
  };

  static getStartDate(period, from, time) {
    if (!period) {
      return;
    }
    period = period.toLowerCase();
    period = period.replace("last ", "")
    let date = moment();
    date.set('second', 0);
    if (time) {
      date.set('hour', time.split(':')[0]);
      time.split(':').length > 1 && date.set('minute', time.split(':')[1]);
    } else {
      date.set('hour', 0);
      date.set('minute', 0);
    }
    switch (period) {
      case 'day':
        return date.subtract((1 * from), 'days').format();
      case 'week':
        return date.subtract((7 * from), 'days').format();
      case 'month':
        return date.subtract((30 * from), 'days').format();
      case '1 month':
        return date.subtract((30 * from), 'days').format();
      case '3 months':
        return date.subtract((90 * from), 'days').format();
      case '6 months':
        return date.subtract((180 * from), 'days').format();
      case '9 months':
        return date.subtract((270 * from), 'days').format();  
      case 'year':
        return date.subtract((365 * from), 'days').format();
      case 'current month':
        return date.startOf('month').format();
    }
  }

  static getEndDate(period, to, time) {
    if (!period) {
      return;
    }
    let date = moment();
    // date.set('second', 59);
    if (time) {
      date.set('hour', time.split(':')[0]);
      time.split(':').length > 1 && date.set('minute', time.split(':')[1]);
    } else {

      date.set('hour', 23);
      date.set('minute', 59);
    }
    period = period.toLowerCase();
    period = period.replace("last ", "")
    switch (period) {
      case 'day':
        return date.add((1 * to), 'days').format();
      case 'week':
        return date.add((7 * to), 'days').format();
      case 'month':
        return date.add((30 * to), 'days').format();
      case '1 month':
        return date.add((30 * to), 'days').format();
      case '3 months':
        return date.add((90 * to), 'days').format();
      case '6 months':
        return date.add((180 * to), 'days').format();
      case '9 months':
        return date.add((270 * to), 'days').format(); 
      case 'year':
        return date.add((365 * to), 'days').format();
      case 'current month':
        return date.add(to, 'days').format();
    }
  }

  static getEndDateForLiveData(period, to, time) {
    if (!period) {
      return;
    }
    let date = moment();
    let hrs = moment().format('HH');
    let mins = moment().format("mm");
    // date.set('second', 59);
    if (time) {
      date.set('hour', time.split(':')[0]);
      time.split(':').length > 1 && date.set('minute', time.split(':')[1]);
    } else {
      date.set('hour', hrs);
      date.set('minute', mins);
    }
    period = period.toLowerCase();
    switch (period) {
      case 'day':
        return date.add((1 * to), 'days').format();
      case 'week':
        return date.add((7 * to), 'days').format();
      case 'month':
        return date.add((30 * to), 'days').format();
      case 'year':
        return date.add((365 * to), 'days').format();
    }
  }

  static getDifferDate = (startDate, endDate) => {
    const startTime = moment(startDate, "HH:mm:ss a");
    const endTime = moment(endDate, "HH:mm:ss a");
    return endTime.diff(startTime, 'hours') + " hours, " +
      endTime.diff(startTime, 'minutes') + " minutes";
    // const startTime = moment(created_at, "HH:mm:ss a");
    // const endTime = moment(modified_at, "HH:mm:ss a");
    // const duration = moment.duration(endTime.diff(startTime));
    // const hours = parseInt(duration.asHours());
    // const minutes = parseInt(duration.asMinutes()) - hours * 60;
    // const finalDate = `${hours} hours, ${minutes} minutes`;
    // var result = endTime.diff(startTime, 'hours') + " Hrs and " +
    // endTime.diff(startTime, 'minutes') + " Mns";
  }

  static getDatesInRange = (startDate, endDate,unixFormat=false, utcTime=false, format="YYYY-MM-DD") =>{
    let date = startDate;

    const dates = [];

    while (date <= endDate) {
      let day = utcTime ? moment(date).utc() : moment(date)
      dates.push(unixFormat ? day.unix(): day.format(format))
      date.add(1, "day");
    }

    return dates;
  }
}