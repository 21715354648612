import React from 'react';
import _ from 'lodash';

function MemoizedWidget({
  Widget,
  refresh,
  widgetId,
  dashboardId,
  dashboardName,
  serviceId,
  dashboardDate,
  dashboardAppConfig,
  widgetContext,
  pathname,
  refreshTime,
  dashboardConfig,
  _dashboardFilterConfig,
  dashboardFilterConfig,
  isFullScreen,
  plantId,
  networkInsightsSiblingFlag,
  networkInsightsConfig,
  networkInsightsActiveId,
  submitFeedbackSuccess,
  onSubmitFeedbackSuccess,
  onWidgetUpdate,
  onNetworkInsightsInitialized,
  onNetworkInsightsActiveIdChange
}) {
  return (
    <div id={widgetId} style={{ height: '100%' }}>
      <Widget
        refresh={refresh}
        widgetId={widgetId}
        serviceId={serviceId}
        dashboardId={dashboardId}
        dashboardName={dashboardName}
        dashboardDate={dashboardDate}
        dashboardAppConfig={dashboardAppConfig}
        widgetContext={widgetContext}
        pathname={pathname}
        refreshTime={refreshTime}
        dashboardConfig={dashboardConfig}
        _dashboardFilterConfig={_dashboardFilterConfig}
        dashboardFilterConfig={dashboardFilterConfig}
        isFullScreen={isFullScreen}
        plantId={plantId}
        networkInsightsSiblingFlag={networkInsightsSiblingFlag}
        networkInsightsConfig={networkInsightsConfig}
        networkInsightsActiveId={networkInsightsActiveId}
        submitFeedbackSuccess={submitFeedbackSuccess}
        onSubmitFeedbackSuccess={onSubmitFeedbackSuccess}
        onWidgetUpdate={onWidgetUpdate}
        onNetworkInsightsInitialized={onNetworkInsightsInitialized}
        onNetworkInsightsActiveIdChange={onNetworkInsightsActiveIdChange}
      ></Widget>
    </div>
  );
}

const doComparison = (prevProps, nextProps) => {
  switch (true) {
    case prevProps.refresh.count !== nextProps.refresh.count:
    case !_.isEqual(
      prevProps.dashboardFilterConfig,
      nextProps.dashboardFilterConfig
    ):
    case prevProps.submitFeedbackSuccess !== nextProps.submitFeedbackSuccess:
    case prevProps.isFullScreen !== nextProps.isFullScreen:
    case prevProps.plantId !== nextProps.plantId:
    case !_.isEqual(prevProps.dashboardAppConfig, nextProps.dashboardAppConfig):
    case prevProps.networkInsightsSiblingFlag !==
      nextProps.networkInsightsSiblingFlag:
    case !_.isEqual(
      prevProps.networkInsightsActiveInfo,
      nextProps.networkInsightsActiveInfo
    ):
    case prevProps.networkInsightsActiveId !==
      nextProps.networkInsightsActiveId:
      return false;

    default:
      return true;
  }
};

export default React.memo(MemoizedWidget, doComparison);
