import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import {
  httpMethodGetJSON,
  httpMethodPut,
  httpMethodPatch,
  httpMethodDelete
} from '../../../../https';
import * as AppConstants from './constant';

export const getNetworkDetailsData = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_DETAILS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network/${action.payload.id}/`);
    })
  );

export const deleteNetworkNode = (action$) =>
  action$.pipe(
    ofType(AppConstants.DELETE_NETWORK_NODE),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/`
      );
    })
  );

export const updateNetworkNode = (action$) =>
  action$.pipe(
    ofType(AppConstants.UPDATE_NETWORK_NODE),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/${action.params.network_id}/node/${action.params.node_id}/`
      );
    })
  );

export const getNetworkParameters = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_PARAMETERS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/parameter/`
      );
    })
  );

export const getNodeParameters = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NODE_PARAMETERS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/parameter/`
      );
    })
  );

export const getNodeConfig = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NODE_CONFIG),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/config/`
      );
    })
  );

export const createNodeParameters = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_NODE_PARAMETERS),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/parameter/`
      );
    })
  );

export const createNodeConfig = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_NODE_CONFIG),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/config/`
      );
    })
  );

export const setNetworkMainNode = (action$) =>
  action$.pipe(
    ofType(AppConstants.SET_NETWORK_MAIN_NODE),
    mergeMap((action) => {
      return httpMethodPatch(
        action,
        `${URL}/network/${action.params.network_id}/main_node/`
      );
    })
  );

export const getNodeSubtree = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NODE_SUBTREE),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/node/${action.payload.node_id}/get_node_tree/`
      );
    })
  );

export const getNetworkNodeLocationConfig = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_NODE_LOCATION_CONFIG),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/node_location_config/`
      );
    })
  );
};

export const updateNetworkNodeLocationConfig = (action$) => {
  return action$.pipe(
    ofType(AppConstants.UPDATE_NETWORK_NODE_LOCATION_CONFIG),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/${action.payload.network_id}/node_location_config/`
      );
    })
  );
};

export const getNetworkAssetCbmAsset = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_ASSET_CBM_ASSET),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset/${action.payload.asset_id}/`
      );
    })
  );
};

export const getNetworkAssetMeta = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_ASSET_META),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-metadata/${action.payload.asset_id}/`
      );
    })
  );
};
