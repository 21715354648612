import queryString from 'query-string';

import * as AppConstants from './constant';

export const getNetworkInsightsOccurrence = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const getNetworkInsightsActions = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const createNetworkInsightsAction = (
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION:
      return {
        ...state,
        loading: true
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};
