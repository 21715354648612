export const GET_NETWORK_INSIGHTS_OCCURRENCE =
  'GET_NETWORK_INSIGHTS_OCCURRENCE';
export const GET_NETWORK_INSIGHTS_OCCURRENCE_SUCCESS =
  'GET_NETWORK_INSIGHTS_OCCURRENCE_SUCCESS';
export const GET_NETWORK_INSIGHTS_OCCURRENCE_FAILURE =
  'GET_NETWORK_INSIGHTS_OCCURRENCE_FAILURE';
export const GET_NETWORK_INSIGHTS_OCCURRENCE_RESET =
  'GET_NETWORK_INSIGHTS_OCCURRENCE_RESET';

export const GET_NETWORK_INSIGHTS_ACTIONS = 'GET_NETWORK_INSIGHTS_ACTIONS';
export const GET_NETWORK_INSIGHTS_ACTIONS_SUCCESS =
  'GET_NETWORK_INSIGHTS_ACTIONS_SUCCESS';
export const GET_NETWORK_INSIGHTS_ACTIONS_FAILURE =
  'GET_NETWORK_INSIGHTS_ACTIONS_FAILURE';
export const GET_NETWORK_INSIGHTS_ACTIONS_RESET =
  'GET_NETWORK_INSIGHTS_ACTIONS_RESET';

export const CREATE_NETWORK_INSIGHTS_ACTION = 'CREATE_NETWORK_INSIGHTS_ACTION';
export const CREATE_NETWORK_INSIGHTS_ACTION_SUCCESS =
  'CREATE_NETWORK_INSIGHTS_ACTION_SUCCESS';
export const CREATE_NETWORK_INSIGHTS_ACTION_FAILURE =
  'CREATE_NETWORK_INSIGHTS_ACTION_FAILURE';
export const CREATE_NETWORK_INSIGHTS_ACTION_RESET =
  'CREATE_NETWORK_INSIGHTS_ACTION_RESET';
