import queryString from 'query-string';

import * as AppConstants from './constant';

export const getNetworkInsights = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};
